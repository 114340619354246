<template>
  <div class="helper">
    <v-img
      contain
      :src="`/images/bg_light${imgBg}.png`"
      max-width="1043"
      class="img-help"
    ></v-img>
    <div class="data-help text-center py-1 px-2 align-center" style="width: 100%">
      <div class="d-inline-flex px-2 bg-full" style="width: 100%; background: red; border-radius: 8px">
        <div>
          Copyright © {{ new Date().getFullYear() }} <a href="https://damudo.at" target="_blank">Damudo</a>, All rights
          Reserved
        </div>
        <v-spacer></v-spacer>
        <div class="">
          BRANCH: <span class="color-primary">{{ backEnd.branch }}</span>, COMMIT: <span
            class="color-primary"
          >{{ backEnd.commit.id }}</span>, TIME: <span
            class="color-primary"
          >{{ backEnd.commit.time | formatDateTimePrecise }}</span>
        </div>
        <div class="px-2"> | </div>
        <div class="">
          {{ $lang.labels.frontEnd }}: <span class="color-primary">{{ frontEnd }}</span>, TIME: <span class="color-primary">{{ frontEndBuildTime | formatDateTimePrecise }} UTC</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/auth'

export default {
  data() {
    return {
      frontEnd: '',
      frontEndBuildTime: '',
      backEnd: {
        branch: '',
        commit: {
          id: '',
          time: ''
        }
      }
    }
  },
  computed: {
    imgBg() {
      const min = Math.ceil(1)
      const max = Math.floor(6)

      return Math.floor(Math.random() * (max - min + 1)) + min
    }
  },
  mounted() {

    this.frontEnd = localStorage.frontendVersion ? localStorage.frontendVersion : ''

    this.frontEndBuildTime = localStorage.frontendBuildtime ? localStorage.frontendBuildtime : ''

    this.backEnd = localStorage.backendVersion ? JSON.parse(localStorage.backendVersion) : {
      branch: '',
      commit: {
        id: '',
        time: ''
      }
    }
  }
}
</script>
<style lang="scss">
.helper {
  position: relative;
  width: 100%;
  height: 100%;
}

.img-help {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.data-help {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
